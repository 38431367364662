import React from "react"
import Layout from "../components/layout"
import View from "../components/Blog/View.js"
import Img from "gatsby-image"
import SideBar from "../components/SideBar"
import { GlobalStateContext } from "../components/GlobalState/GlobalState.js"
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css"
import "./global.scss"

const BlogPostList = (params) => {
  // console.log( "params:", params )
  const pageContext = params.pageContext
  return (
    <GlobalStateContext.Consumer>
      {g => {
        // console.log("BlogPostList, g=", g)
        return (
          <Layout
            pageContext={pageContext}
            seotitle={pageContext.title}
            description={pageContext.description}
            image="/images/logo.svg"
            globalState={g}
            Container="container"
          >
            <div className="column-main">
              {pageContext.authorImage ? (
                <Img
                  className="author-image image is-128x128"
                  fluid={pageContext.authorImage}
                  alt={pageContext.title}
                />
              ) : (
                ""
              )}
              <div
                dangerouslySetInnerHTML={{ __html: pageContext.html }}
                className="page-text"
              />
              <View
                globalState={g}
                pageContext={pageContext}
                className="PostList"
              />
            </div>
            <SideBar pageContext={pageContext} />
          </Layout>
        )
      }}
    </GlobalStateContext.Consumer>
  )
}

export default BlogPostList
