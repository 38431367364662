import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PostList = ({ posts, language, seeMore }) => {
  return (
    <div className="PostList">
      {posts.map((post, idx) => {
        const localDate = new Date(post.date).toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' })
        return (
          <div key={idx} className="card">
            <div className="card-image">
              <figure className="image">
                <Link to={"/" + language + post.slug+"/"}>
                  <Img fluid={post.featuredImage} alt={post.title} />
                </Link>
              </figure>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <div className="subtitle is-6 post-cat-link">
                    <Link
                      className={post.category.frontmatter.title}
                      to={"/" + language + post.category.fields.slug+"/"}
                    >
                      {post.category.frontmatter.title}&nbsp;
                    </Link>
                    <p>{localDate}</p>
                  </div>
                  <h2>
                    <Link to={"/" + language + post.slug+"/"}>{post.title}</Link>
                  </h2>
                  <p className="post-excerpt">{post.excerpt}</p>
                </div>
              </div>
              <p className="has-text-right read-more">
                <Link to={"/" + language + post.slug+"/"}>
                  <span>{seeMore}</span>
                  <span className="read-more-arrow">
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </span>
                </Link>
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PostList
