import React from "react"
import Subscribe from "../components/Subscribe"
import EBookBox from "../components/eBookBox"
import PopularPosts from "../components/PopularPosts"

export default class SideBar extends React.Component {
  render() {
    const sideContent = this.props.pageContext.sideContent
    const posts = this.props.pageContext.allPosts
    const language =this.props.pageContext.language
    return (
      <div className="column-side">
        <Subscribe language={language} subscribeTitle={sideContent[0].subscribeTitle} subscribeText={sideContent[0].subscribeText} />
        <div className="box">
          <EBookBox
            title={sideContent[0].ebookTitle}
            text={sideContent[0].ebookText}
            ebookDownload={sideContent[0].ebookDownload}
            textCTA={sideContent[0].textCTA}
          />
        </div>
        <div className="box">
          <div className="media-content">
            <div className="content">
              <h2>{sideContent[0].aboutTitle}</h2>
              <p>{sideContent[0].aboutText}</p>
              <hr />
              <h2>{sideContent[0].popularTitle}</h2>
              <PopularPosts language={language} posts={posts} />
              <hr />
              <h2>{sideContent[0].followTitle}</h2>
              <div className="is-social">
                <span className="icon is-large is-twitter">
                  <a
                    href={sideContent[0].linkTwitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-lg fa-twitter"></i>
                  </a>
                </span>
                <span className="icon is-large is-facebook">
                  <a
                    href={sideContent[0].linkFacebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-lg fa-facebook-f"></i>
                  </a>
                </span>
                <span className="icon is-large is-linkedin">
                  <a
                    href={sideContent[0].linkLinkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-lg fa-linkedin-in"></i>
                  </a>
                </span>
                <span className="icon is-large is-instagram">
                  <a
                    href={sideContent[0].linkInstagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-lg fa-instagram"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
