import React from "react"
import { Link } from "gatsby"

export default class PopularPosts extends React.Component {
  render() {
    const posts = this.props.posts
    const language = this.props.language
    return (
      <div>
        {posts
         .filter(post => post.popular)
         .map((post, idx) => {
          const localDate = new Date(post.date).toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' })
          return idx > 2 ? (
            ""
          ) : (
            <p key={idx}>
              <Link to={"/"+language+post.slug+"/"}>{post.title}</Link>
              <br />
              <span className="has-text-weight-light">{localDate}</span>
            </p>
          )
        })}
      </div>
    )
  }
}
