import React from "react"
import {Link} from "gatsby"

class Pagination extends React.Component {
  render() {
    const { pageIndex, numPages, pathPref } = this.props.pageContext   // [0,1,2,3]
    const prevPage = (pageIndex === 0 ? null : pageIndex === 1 ? "" : pageIndex)
    const nextPage = (pageIndex+1 < numPages ? pageIndex+2 : "")
    if(numPages <= 1) {
      return (
        null
      )
    }
    //console.log("numPages=", numPages)
    return (
      <React.Fragment>
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
          <Link className={(pageIndex > 0) ? "pagination-previous" : "pagination-previous is-disabled"} to={(pageIndex > 0) ? (pathPref + prevPage)+ "/" : ""} rel="prev"><i className="fas fa-chevron-left"></i></Link>
          <Link className={(pageIndex+1 < numPages) ? "pagination-next" : "pagination-next is-disabled"} to={(pageIndex+1 < numPages) ? (pathPref + nextPage) + "/" : ""} rel="next"><i className="fas fa-chevron-right"></i></Link>
          <ul className="pagination-list">
          {Array.from({ length:  numPages }).map( (_, idx) => {
            if (numPages > 5) {
              if(idx < 5 && pageIndex < 3) {
                return (
                  <li key={idx}><Link className={"pagination-link"+ (idx===pageIndex ? " is-current" : "")} aria-label={"Go to page " + (idx+1)} to={(idx > 0) ? (pathPref+(idx+1)) + "/" : pathPref} >{idx+1}</Link></li>
                )
              } else if(idx > 2 && pageIndex > 2 && idx===pageIndex && pageIndex+2 < numPages) {
                // console.log('idx=', idx)
                // console.log('pageIndex=', pageIndex)
                return (
                  <React.Fragment key={idx}>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx-1)} to={(pathPref+(idx-1)) + "/"} >{idx-1}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + idx} to={(pathPref+(idx)) + "/"} >{idx}</Link></li>                    
                    <li><Link className={"pagination-link is-current"} aria-label={"Go to page " + (idx+1)} to={(pathPref+(idx+1)) + "/"}>{idx+1}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx+2)} to={(pathPref+(idx+2)) + "/"} >{idx+2}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx+3)} to={(pathPref+(idx+3)) + "/"} >{idx+3}</Link></li>
                  </React.Fragment>
                )
              } else if (pageIndex+2 > numPages && idx===pageIndex) {
                return (
                  <React.Fragment key={idx}>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx-3)} to={(idx > 0) ? (pathPref+(idx-3)) + "/" : pathPref + "/"} >{idx-3}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx-2)} to={(idx > 0) ? (pathPref+(idx-2)) + "/" : pathPref + "/"} >{idx-2}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx-1)} to={(idx > 0) ? (pathPref+(idx-1)) + "/" : pathPref + "/"} >{idx-1}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + idx} to={(idx > 0) ? (pathPref+(idx)) + "/" : pathPref + "/"} >{idx}</Link></li>
                    <li><Link className={"pagination-link"+ (idx===pageIndex ? " is-current" : "")} aria-label={"Go to page " + (idx+1)} to={(idx > 0) ? (pathPref+(idx+1)) + "/" : pathPref + "/"} >{idx+1}</Link></li> 
                  </React.Fragment>
                )
              } else if (pageIndex+3 > numPages && idx===pageIndex) {
                return (
                  <React.Fragment key={idx}>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx-2)} to={(idx > 0) ? (pathPref+(idx-2)) + "/" : pathPref + "/"} >{idx-2}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx-1)} to={(idx > 0) ? (pathPref+(idx-1)) + "/" : pathPref + "/"} >{idx-1}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + idx} to={(idx > 0) ? (pathPref+(idx)) + "/" : pathPref + "/"} >{idx}</Link></li>
                    <li><Link className={"pagination-link"+ (idx===pageIndex ? " is-current" : "")} aria-label={"Go to page " + (idx+1)} to={(idx > 0) ? (pathPref+(idx+1)) + "/" : pathPref + "/"} >{idx+1}</Link></li>
                    <li><Link className={"pagination-link"} aria-label={"Go to page " + (idx+2)} to={(idx > 0) ? (pathPref+(idx+2)) + "/" : pathPref + "/"} >{idx+2}</Link></li> 
                  </React.Fragment>
                )
              }
            } else if (numPages <= 5) {
              if(idx < 5) {
                return (
                  <li key={idx}><Link className={"pagination-link"+ (idx===pageIndex ? " is-current" : "")} aria-label={"Go to page " + (idx+1)} to={(idx > 0) ? (pathPref+(idx+1)) + "/" : pathPref} >{idx+1}</Link></li>
                )
              }
            }
            return ""
          })}
          </ul>
        </nav>
      </React.Fragment>
    )
  }
}

export default Pagination
