import React from "react"
//import {Link} from "gatsby"
import PostList from "../PostList"
import Pagination from "./Pagination"
//import { InfiniteScroll } from "./InfiniteScroll"

/** Template for "home" page with infinite scroll and fallback to pagination. */
class View extends React.Component {
  constructor(props) {
    //const postsPerPage = props.pageContext.postsPerPage
    //const page = props.pageContext.page
    super(props)
    props.globalState.updateState({
      // items: props.pageContext.postsToShow.slice(postsPerPage * (page-1), postsPerPage),
      cursor: props.pageContext.page,
      searchableContent: props.pageContext.postsToShow,
      searchedContent: props.pageContext.postsToShow,
    })
  }
  render() {
    //const g = this.props.globalState
    const pageContext = this.props.pageContext
    //const postsPerPage = pageContext.postsPerPage
    //const numPages = pageContext.numPages
    const staticContent = pageContext.staticContent[0]

    // console.log( "this.props.pageContext", this.props.pageContext )
    return (
      <React.Fragment>
        <PostList posts={pageContext.posts} language={pageContext.language} seeMore={staticContent.seeMore}/>
        <Pagination pageContext={pageContext}/>
      </React.Fragment>
    )
  }
}

export default View
